@import "../../../data/styles.css";

.form-wrapper {
	/* max-width: 600px; */
	margin: 0 auto;
	padding: 20px;
	/* border: 1px solid #ddd; */
	border-radius: 8px;
	/* background-color: #f9f9f9; */
}

h4 {
	font-size: 1.5rem;
	margin-bottom: 20px;
	text-align: center;
}

.form-group {
	margin-bottom: 15px;
}

.form-control {
	width: 100%;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
}

.form-text {
	margin-top: 5px;
	font-size: 0.9rem;
	color: #777;
}

.submit-button {
	display: block;
	width: 100%;
	padding: 10px;
	background-color: var(--link-color) !important;
	color: white;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

textarea.form-control {
	resize: none; /* Prevents resizing */
}

.submit-button:hover {
	background-color: #109586;
}

.alert {
	padding: 10px;
	margin-bottom: 15px;
	border-radius: 4px;
}

.alert-danger {
	background-color: #f8d7da;
	color: #721c24;
}

.alert-success {
	background-color: #d4edda;
	color: #155724;
}
