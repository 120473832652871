.works {
	width: 100%;
	display: flex;
	justify-content: center;
}

.works-body {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.work-row {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px; /* Adjust as needed */
}

.work {
	width: calc(50% - 60px); /* Adjust based on your preference for spacing */
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	background-color: #fff;
	padding: 20px;
}

.work-info {
	margin-bottom: 10px;
}

.work-title {
	font-size: 15px;
	font-weight: 700;
	color: var(--secondary-color);
}

.work-subtitle,
.work-duration,
.work-location {
	font-size: 12px;
	color: var(--tertiary-color);
}

.skills-award {
	width: calc(100% - 40px);
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	background-color: #fff;
	padding: 20px;
}
