.slider-container {
	width: 100%;
	/* max-width: 800px; */
	margin: 0 auto;
	padding: 0 15px; /* Add padding for better layout on smaller screens */
}

.carousel-slide {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.carousel-image {
	width: 100%;
	height: auto;
	object-fit: cover;
}

/* Responsive adjustments */
@media (max-width: 768px) {
	.slider-container {
		max-width: 90%;
	}
}

@media (max-width: 480px) {
	.slider-container {
		max-width: 100%;
		padding: 0 10px; /* Reduce padding on very small screens */
	}
}
