@import "../../data/styles.css";
.contact-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.contact-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.contact-subtitle {
	width: 100% !important;
}

.contact-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-top: 120px;
}

.socials-container {
	/* display: flex; */
	max-width: fit-content;
	flex-direction: column;
	margin-top: 80px;
}

.contact-socials {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.social,
.email-wrapper {
	display: flex;
	padding-bottom: 20px;
}

.social a,
.email-wrapper a {
	text-decoration: none;
}

.social-icon {
	font-size: 15px;
	color: var(--secondary-color);
}

.social-text {
	color: var(--secondary-color);
	padding-left: 30px;
	margin-top: -20px;
	font-weight: 600;
	font-size: 14px;
}

.email::before {
	content: "";
	display: block;
	padding-top: 20px;
	border-top: 3px solid #f4f4f5;
	margin-top: 20px;
}

.email-wrapper {
	padding-top: 20px;
}

@media (max-width: 768px) {
	.contact-logo-container {
		display: none;
	}
}
.card {
	margin-top: 10px;
	border-radius: 8px;
	padding: 20px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.side-by-side-container {
	display: flex;
	align-items: center;
	gap: 10px;
}

.socials-container,
.contact-form-container {
	flex: 1;
}

@media (max-width: 768px) {
	.side-by-side-container {
		flex-direction: column;
		gap: 10px;
	}
}
