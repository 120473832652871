@import "../../data/styles.css";

.about-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.about-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
	z-index: 999;
}

.about-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.about-first-area {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}

.about-first-area-left-side {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.about-title {
	width: 85% !important;
}

.about-subtitle {
	width: 85% !important;
}

.about-first-area-right-side {
	display: flex;
	align-items: center;
}

.about-image-container {
	width: 370px;
	height: 370px;
}

.about-image-wrapper {
	overflow: hidden;
	border-radius: 10%;
	/* transform: rotate(3deg); */
}

.about-image-wrapper img {
	width: 100%;
}

.about-socials {
	display: flex;
	justify-content: left;
	align-items: center;
	padding-top: 30px;
}

.about-social-icon {
	padding-right: 20px;
	font-size: 25px;
	transition: color 0.2s ease-in-out;
	color: var(--secondary-color);
}

.about-social-icon:hover {
	color: var(--primary-color);
}

.about-after-title {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.about-projects {
	flex-basis: 300px;
	padding-top: 40px;
}

.about-recommendations {
	align-items: center;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between; /* Adjust spacing between articles */
	padding-top: 30px;
}

.about-recommendation-container {
	width: calc(100% / 2);
	box-sizing: border-box;
	overflow: hidden;
}
.about-recommendation {
	padding: 20px;
	width: calc(50% - 10px); /* Two cards per row with some space in between */
	padding-bottom: 5px;
	box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

.about-works {
	flex-basis: 600px;
	padding-top: 30px;
}

@media (max-width: 1024px) {
	.about-first-area {
		flex-direction: column;
	}

	.about-first-area-left-side {
		display: flex;
		flex-direction: column; /* Changed: set direction to column */
		align-items: flex-start;
		width: 100%;
		order: 2;
	}

	.about-title {
		width: 100% !important;
		margin-top: 20px;
		order: 2;
	}

	.about-subtitle {
		width: 100% !important;
		margin-top: 10px;
		order: 3;
	}

	.about-first-area-right-side {
		width: 100%;
		order: 1;
	}

	.about-image-container {
		padding-top: 50px;
		padding-bottom: 50px;
		margin-left: 10px;
		order: 1;
	}

	.about-after-title {
		flex-direction: column;
	}

	.about-projects {
		flex-basis: auto;
	}

	.about-recommendations {
		flex-basis: auto;
	}

	.about-works {
		flex-basis: auto;
		margin-left: -25px;
		margin-right: -25px;
	}

	.about-recommendation {
		padding: 20px;
		width: 100%; /* Full width for smaller screens */
		margin-bottom: 20px; /* Space between articles on smaller screens */
	}
}

@media (max-width: 768px) {
	.about-recommendation {
		width: 100%; /* Full width for smaller screens */
		margin-bottom: 20px; /* Space between articles on smaller screens */
	}
	.about-logo-container {
		display: none;
	}

	.about-container {
		padding-top: 60px;
	}
}
